import { WppTypography, WppToggle, WppButton } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useActivateCollectionTenant } from 'api/collections/mutations/useActivateCollectionTenant'
import { useCollectionTenantAvailabilityApi } from 'api/collections/queries/useCollectionTenantAvailabilityApi'
import { useInfiniteCollectionTenantsAvailabilityApi } from 'api/collections/queries/useInfiniteCollectionTenantsAvailabilityApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delays } from 'constants/delays'
import { PageSizes } from 'constants/pageSizes'
import { useCollectionData } from 'hooks/useCollectionData'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useQueryCacheUpdate } from 'hooks/useQueryCacheUpdate'
import { useToast } from 'hooks/useToast'
import { AvailabilityTable } from 'pages/collections/details/tabs/availability/components/availabilityTable/AvailabilityTable'
import { EnablingAvailabilityInfoMessage } from 'pages/collections/details/tabs/availability/components/enaiblingAvailabilityInfoMessage/EnablingAvailabilityInfoMessage'
import { MayBeNull } from 'types/common/utils'
import { ProductTenant, TenantStatus } from 'types/products/tenant'
import { Flex } from 'ui-base/flex/Flex'
import { unpackApiError } from 'utils/form'

import * as S from 'pages/collections/details/tabs/availability/CollectionAvailability.styled'

export const CollectionAvailability = () => {
  const { t } = useTranslation(['collections', 'common'])
  const { showToast } = useToast()
  const queryClient = useQueryClient()
  const { collectionId } = useParams()
  const { collection } = useCollectionData()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ activeTenantId, search }, setState] = useSetState<{
    activeTenantId: MayBeNull<ProductTenant['tenantId']>
    search: string
  }>({
    activeTenantId: null,
    search: '',
  })
  const searchRef = useRef<HTMLWppInputElement>(null)
  const isAvailabilityActivationDisabled = !collection?.readyForOs
  const showSearchInput = collection?.readyForOs

  const {
    data: collectionTenant,
    isLoading: isCollectionTenantLoading,
    isError: isCollectionTenantError,
    error: collectionTenantError,
    isPreviousData: isCollectionTenantPreviousData,
  } = useCollectionTenantAvailabilityApi({
    params: {
      collectionId: collectionId!,
    },
  })

  const {
    data: tenants,
    isLoading: isTenantsLoading,
    isError: isTenantsError,
    error: tenantsError,
    isPreviousData: isTenantsPreviousData,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteCollectionTenantsAvailabilityApi({
    params: {
      collectionId: collectionId!,
      size: PageSizes.PAGINATED_TENANTS,
      searchPhrase: search,
    },
  })

  const { updateItems } = useQueryCacheUpdate<ProductTenant>({
    queryKey: [
      ApiQueryKeys.COLLECTION_TENANTS_AVAILABILITY,
      {
        collectionId: collection?.id,
        size: PageSizes.PAGINATED_TENANTS,
        searchPhrase: search,
      },
    ],
  })
  const { mutateAsync: activateCollectionTenant } = useActivateCollectionTenant({
    onSuccess: (_, params) => {
      updateItems({
        updateMatchedItems: staleItem =>
          staleItem.tenantId === params.tenantId ? { access: TenantStatus.ACTIVE } : undefined,
      })
      queryClient.invalidateQueries([ApiQueryKeys.COLLECTION_TENANT_AVAILABILITY])
    },
  })

  const activateSingleTenantAvailability = useCallback(
    async (tenantId: ProductTenant['tenantId']) => {
      try {
        await activateCollectionTenant({
          tenantId,
          collectionId: collection?.id!,
        })

        await Promise.all([
          queryClient.invalidateQueries([ApiQueryKeys.COLLECTION]),
          queryClient.invalidateQueries([ApiQueryKeys.COLLECTIONS]),
        ])

        showToast({
          message: t('collections|availability.notifications.success_message_change_access'),
          type: 'success',
        })
      } catch (apiError) {
        const { detail } = unpackApiError(apiError)
        const defaultError = t('collections|availability.notifications.failure_message_change_access')

        showToast({
          message: detail || defaultError,
          type: 'error',
        })
      }
    },
    [collection?.id, queryClient, activateCollectionTenant, t, showToast],
  )

  const setSearchDebounced = useDebounceFn((search: string) => {
    setState({ search: search.trim() })
  }, Delays.Search)

  return (
    <S.CollectionAvailabilityCard size="l" data-testid="collection-availability-card-content">
      <Flex slot="header" align="center" justify="between" grow={1}>
        <WppTypography type="xl-heading" data-testid="collection-availability-card-title">
          {t('collections|availability.title')}
        </WppTypography>
        <WppToggle
          labelConfig={{ text: t('collections|availability.all_workspaces') }}
          disabled
          required
          data-testid="toggle-global-availability"
        />
      </Flex>

      <EnablingAvailabilityInfoMessage />

      <Flex direction="column" gap={20}>
        <AvailabilityTable
          tenants={collectionTenant ? [collectionTenant] : []}
          isLoading={isCollectionTenantLoading}
          isError={isCollectionTenantError}
          error={collectionTenantError}
          isPreviousData={isCollectionTenantPreviousData}
          isGloballyAvailable={false}
          isCurrentTenant
          isAvailabilityDisabled={isAvailabilityActivationDisabled}
          updateControlTenant={(activeTenantId: ProductTenant['tenantId']) => setState({ activeTenantId })}
          activateSingleTenantAvailability={activateSingleTenantAvailability}
          itemsPerPage={1}
          data-testid="collection-tenant-availability-table"
        />

        {showSearchInput && (
          <S.SearchInput
            ref={searchRef}
            size="s"
            type="search"
            onWppChange={({ detail }) => setSearchDebounced(detail?.value!)}
            placeholder={t('collections|availability.search.placeholder')}
            data-testid="search-other-workspaces-input"
          />
        )}

        <AvailabilityTable
          tenants={tenants}
          isLoading={isTenantsLoading}
          isError={isTenantsError}
          error={tenantsError}
          isPreviousData={isTenantsPreviousData}
          isGloballyAvailable={false}
          isAvailabilityDisabled={isAvailabilityActivationDisabled}
          updateControlTenant={(activeTenantId: ProductTenant['tenantId']) => setState({ activeTenantId })}
          activateSingleTenantAvailability={activateSingleTenantAvailability}
          isSearchApplied={!!search.length}
          resetSearch={() => setState({ search: '' })}
          data-testid="tenants-availability-table"
        />
        {hasNextPage && (
          <Flex justify="center">
            <WppButton variant="secondary" loading={isFetching} size="m" onClick={() => fetchNextPage()}>
              {t('common|load_more')}
            </WppButton>
          </Flex>
        )}
      </Flex>
    </S.CollectionAvailabilityCard>
  )
}
